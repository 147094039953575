import * as React from "react"
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles({
	root: {
		width: "100%",
		maxWidth: "24rem",
		padding: "0",
		overflow: "visible",
	},
	paragraphWrap: {
		padding: "1rem",
		"@media screen and (min-width: 767px)": {
			minHeight:"106px",
			display:"flex",
			flexFlow: "column wrap",
			justifyContent:"center",
			alignItems:"center",
		}
	},
	iconwrap: {
		height: "17rem",
		display: "flex",
		flexFlow: "row wrap",
		alignItems: "center",
		justifyContent: "center",
	},
	paragraph: {
		margin: "0",
		padding: "0",
		fontFamily: "'Avenir LT W01_65 Medium1475532', sans-serif",
		color: "#2a2a32",
		"@media screen and (max-width: 767px)": {
			fontSize: "2rem",
			lineHieight: "3rem"
		}
	}
});

export default function ImgMediaCard({ imgalt, imgsrc, siteAudience, content, delay }) {
	const classes = useStyles();

	return (
		<Card className={classes.root}>
			<div className="iconCard"
				data-sal="slide-up"
				data-sal-duration="8000"
				data-sal-delay={delay}
				data-sal-easing="ease-out-expo">
			<div className={[classes.iconwrap, siteAudience].join(' ')}>
				<CardMedia
					component="img"
					alt={imgalt}
					height="136"
					image={imgsrc}
					title={imgalt}
				/>
			</div>
			<CardContent className={classes.paragraphWrap}>
				<p className={classes.paragraph}>
					{content}
				</p>
			</CardContent>
			</div>
		</Card>
	);
}
